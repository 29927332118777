import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  forgotPasswordActionTypes,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
} from "./ActionTypes";
import { forgotPasswordDetails, forgotPasswordValue } from "./Model";
import { ApiHeader } from "components/Utility";

export const forgotPasswordApi = (email: forgotPasswordDetails) => {
  return function (dispatch: Dispatch<forgotPasswordActionTypes>) {
    dispatch({
      type: FORGOT_PASSWORD_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/profileapp/forgot-password/${email.email}/`, { headers: ApiHeader })
      .then((res) => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: FORGOT_PASSWORD_FAIL,
          payload: {} as forgotPasswordValue,
          errRes: error.response.data.message || error.response.data.error,
        });
      });
  };
};
