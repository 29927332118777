import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  emailOtpGenerationActionTypes,
  EMAIL_OTP_GENERATION_FAIL,
  EMAIL_OTP_GENERATION_LOADING,
  EMAIL_OTP_GENERATION_SUCCESS,
} from "./ActionTypes";
import { emailOtpGenerationDetails, emailOtpGenerationValue } from "./Model";
import { ApiHeader } from "components/Utility";

export const emailOtpGenerationApi = (email: emailOtpGenerationDetails) => {
  return function (dispatch: Dispatch<emailOtpGenerationActionTypes>) {
    dispatch({
      type: EMAIL_OTP_GENERATION_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/email-otp-generation/`, email, { headers: ApiHeader })
      .then((res) => {
        dispatch({
          type: EMAIL_OTP_GENERATION_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: EMAIL_OTP_GENERATION_FAIL,
          payload: {} as emailOtpGenerationValue,
          errRes: error.response.data.message,
        });
      });
  };
};
