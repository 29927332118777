import { ContestListRes } from "./Model";
export const CONTEST_LIST_LOADING = "CONTEST_LIST_LOADING";
export const CONTEST_LIST_SUCCESS = "CONTEST_LIST_SUCCESS";
export const CONTEST_LIST_FAIL = "CONTEST_LIST_FAIL";
export const CONTEST_LIST_DATA = "CONTEST_LIST_DATA";
export const CONTEST_LIST_UPDATE_API_MSG = "CONTEST_LIST_UPDATE_API_MSG";
export const CONTEST_LIST_UPDATE_API_RES = "CONTEST_LIST_UPDATE_API_RES";

export interface ContestListState {
  contestListRes: ContestListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ContestListLoading {
  type: typeof CONTEST_LIST_LOADING;
  loading: boolean;
}
export interface ContestListSuccess {
  type: typeof CONTEST_LIST_SUCCESS;
  payload: ContestListRes;
  successMsg: string;
}
export interface ContestListFail {
  type: typeof CONTEST_LIST_FAIL;
  payload: ContestListRes;
  errorMsg: string;
  status: number;
}
export interface ContestListUpdateAPIMsg {
  type: typeof CONTEST_LIST_UPDATE_API_MSG;
  payload: ContestListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ContestListUpdateAPIRes {
  type: typeof CONTEST_LIST_UPDATE_API_RES;
  payload: ContestListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ContestListActionTypes =
  | ContestListLoading
  | ContestListSuccess
  | ContestListFail
  | ContestListUpdateAPIMsg
  | ContestListUpdateAPIRes;
