import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Grid, Container, Stack, Tab, Chip } from "@mui/material";
import MWPageTitle from "components/MWPageTitle";
import LinkedPicture from "./CompetitionDetails/LinkedPicture";
import UnLinkedPicture from "./CompetitionDetails/UnLinkedPicture";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import MWLoader from "components/MWLoader";
import { CompetitionList } from "redux/Dashboard/ContestList/Model";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { fetchContestList } from "../redux/Dashboard/ContestList/API";
import { getPatientDetailsListByCompitionId } from "redux/CompetitionDetails/PatientDetailsListOfPatientByCompetitionId/API";
import { GetPatientDetailsListByCompetitionIdBody } from "redux/CompetitionDetails/PatientDetailsListOfPatientByCompetitionId/Model";
import { getUnLinkedImageListByUser } from "../redux/CompetitionDetails/UnlinkedUploadedImageListByUser/API";
import AddImage from "./CompetitionDetails/AddImage";

export default function PatientDetailsListByCompetition() {
  const userId = localStorage.getItem("defaultUserId");
  const dispatch = useDispatch();
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const { competitionpageno } = useParams() as {
    competitionpageno: string;
  };
  const [value, setValue] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const linkedImageListRes = useSelector(
    (state: AppState) => state.getPatientDetailsByCompetitionRes,
  );
  const unLinkedImageListRes = useSelector(
    (state: AppState) => state.getUnLinkedImageListByUserRes,
  );

  const contestListRes = useSelector((state: AppState) => state.contestListRes);
  const contestListResFiltered: CompetitionList = _.filter(
    contestListRes.contestListRes.competitionList,
    ["id", Number(competitionid)],
  )[0];

  useEffect(() => {
    if (contestListResFiltered === undefined) {
      dispatch(fetchContestList(Number(competitionpageno)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contestListResFiltered]);

  useEffect(() => {
    dispatch(getPatientDetailsListByCompitionId(patientDetailsBody, 1));
    dispatch(getUnLinkedImageListByUser(patientDetailsBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const patientDetailsBody = {
    competitionId: String(competitionid),
    patientId: String(userId),
  } as GetPatientDetailsListByCompetitionIdBody;

  const [addImageDialogEnable, setAddImageDialogEnable] = useState(false);
  const updateAddImageDialog = (passedVal: boolean) => {
    setAddImageDialogEnable(passedVal);
  };

  return (
    <Box>
      {linkedImageListRes.loading || unLinkedImageListRes?.loading ? <MWLoader /> : null}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={`${
                  contestListResFiltered !== undefined
                    ? contestListResFiltered?.displayName + " details"
                    : ""
                }`}
                enableCount={true}
                reload={true}
              />
            </Grid>
            {value === "2" ? (
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={1}
                >
                  <Grid item>
                    <Button variant="outlined" onClick={() => setAddImageDialogEnable(true)}>
                      Add New Patient Details
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <TabContext value={String(value)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Active Picture</Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={linkedImageListRes?.patientDetailsListOfCompetitionIdRes?.count}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        dispatch(getPatientDetailsListByCompitionId(patientDetailsBody, 1));
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Inactive Picture</Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={unLinkedImageListRes?.patientDetailsListOfCompetitionIdRes?.count}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        dispatch(getUnLinkedImageListByUser(patientDetailsBody, 1));
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <LinkedPicture />
            </TabPanel>
            <TabPanel value="2">
              <UnLinkedPicture />
            </TabPanel>
          </TabContext>
        </Stack>
      </Container>
      {addImageDialogEnable ? (
        <AddImage enable={addImageDialogEnable} dialogUpdateState={updateAddImageDialog} />
      ) : null}
    </Box>
  );
}
