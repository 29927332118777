import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Container, Stack } from "@mui/material";

interface PageLayoutProps {
  children?: ReactNode;
}
const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  return (
    <Container maxWidth="xl">
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        {children}
      </Stack>
    </Container>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
};

export default PageLayout;
