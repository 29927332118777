import {
  GetUserListActionTypes,
  USER_LIST_FAIL,
  USER_LIST_LOADING,
  USER_LIST_SUCCESS,
  UserListState,
  USER_LIST_API_MSG,
  USER_LIST_API_RES,
} from "./ActionTypes";
import { GetUserListDetails } from "./Model";

const initialStateGetPosts: UserListState = {
  loading: false,
  patientDetailsListOfCompetitionIdRes: {} as GetUserListDetails,
  successMessage: "",
  errorMessage: "",
  status: 0,
};
export const getUserListReducer = (
  state = initialStateGetPosts,
  action: GetUserListActionTypes,
): UserListState => {
  switch (action.type) {
    case USER_LIST_LOADING:
      return {
        ...state,
        loading: true,
        patientDetailsListOfCompetitionIdRes: {} as GetUserListDetails,
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: action.payload,
        successMessage: action.successMessage,
      };
    case USER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: {} as GetUserListDetails,
        errorMessage: action.errorMessage,
        status: action.status,
      };
    case USER_LIST_API_MSG:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: action.payload,
        successMessage: action.successMessage,
        errorMessage: action.errorMessage,
        status: action.status,
      };
    case USER_LIST_API_RES:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: {} as GetUserListDetails,
        successMessage: action.successMessage,
        errorMessage: action.errorMessage,
        status: action.status,
      };
    default:
      return state;
  }
};
