import { DropDownResponse } from "./Model";

// Patient Meeting Loading State
export const DROP_DOWN_LOADING = "DROP_DOWN_LOADING";
export const DROP_DOWN_SUCCESS = "DROP_DOWN_SUCCESS";
export const DROP_DOWN_FAIL = "DROP_DOWN_FAIL";
export const DROP_DOWN = "DROP_DOWN";

export interface dropdownDetails {
  patientList: DropDownResponse;
  loading: boolean;
  errRes: string;
}
export interface dropdownLoading {
  type: typeof DROP_DOWN_LOADING;
}
export interface dropdownSuccess {
  type: typeof DROP_DOWN_SUCCESS;
  payload: DropDownResponse;
  errRes: string;
}
export interface dropdownFail {
  type: typeof DROP_DOWN_FAIL;
  errRes: string;
}

interface dropdownAction {
  type: typeof DROP_DOWN;
  payload: DropDownResponse;
  errRes: string;
}

export type dropdownActionTypes = dropdownAction | dropdownLoading | dropdownSuccess | dropdownFail;
