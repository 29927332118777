import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Alert,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Controls = ({ zoomIn, zoomOut, resetTransform }: ReactZoomPanPinchContentRef) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 2,
      transform: "translate(10px, 10px)",
      maxWidth: "calc(100% - 20px)",
    }}
  >
    <Button onClick={() => zoomIn()}>+ Zoom in</Button>
    <Button onClick={() => zoomOut()}>- Zoom out</Button>
    <Button onClick={() => resetTransform()}>x Reset</Button>
  </Box>
);

interface Props {
  enable: boolean;
  dialogUpdateState: boolean | any;
  selectedItem: string | any;
  selectedItemUpdateValue: string | any;
}
export default function DocumentViewer({
  enable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateValue,
}: Props) {
  useEffect(() => {
    setOpen(enable);
  }, [enable]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    dialogUpdateState(false);
    selectedItemUpdateValue("");
    setImageItem("");
  };
  const [imageItem, setImageItem] = useState("");
  useEffect(() => {
    selectedItem !== "" ? setImageItem(selectedItem) : setImageItem("");
  }, [selectedItem]);

  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Image details
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper square>
              {imageItem !== "" ? (
                <TransformWrapper initialScale={1} initialPositionX={50} initialPositionY={50}>
                  {(utils) => (
                    <React.Fragment>
                      <Controls {...utils} />
                      <TransformComponent
                        wrapperStyle={{
                          maxWidth: "auto",
                          height: "100%",
                          maxHeight: "100vh",
                          border: "solid 2px gray",
                        }}
                      >
                        {
                          // eslint-disable-next-line jsx-a11y/img-redundant-alt
                          <img
                            style={{
                              height: "calc(100vh - 200px)",
                              objectFit: "cover",
                              width: "100%",
                              maxInlineSize: "100%",
                              blockSize: "auto",
                            }}
                            src={imageItem}
                            alt="Participant-uploaded-image"
                          />
                        }
                      </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              ) : (
                <Grid container direction="row" justifyContent="center" alignItems="center" pt={8}>
                  <Alert variant="outlined" severity="info">
                    Image File Not Uploaded
                  </Alert>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
