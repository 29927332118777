import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Link, Typography } from "@mui/material";
import LoginScreen from "../../image/design-assets/featureimage-patient.png";
import logo from "../../image/logo/medicalWISDOM.png";

interface OnboardingLayoutProps {
  children?: ReactNode;
}
const OnboardingLayout: FC<OnboardingLayoutProps> = ({ children }) => {
  return (
    <Grid container item direction="row" justifyContent="space-between" sx={{ height: "100vh" }}>
      <Grid xs={12} container item>
        <Grid
          md={6}
          container
          item
          alignItems="center"
          justifyContent="center"
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box pt={10}>
            <img
              src={LoginScreen}
              alt=""
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Grid>
        <Grid xs={12} md={6} container item alignItems="center" justifyContent="center">
          <Grid container item alignItems="center" justifyContent="center">
            <Grid container item xs={11} sm={7} lg={7}>
              <Link underline="none" href="https://craftsocially.com">
                <img src={logo} alt="Logo" height="80" />
              </Link>
              <Box my={3}>
                <Typography color="textPrimary" variant="h5" display="inline">
                  <b>Welcome to CraftSocially</b>
                </Typography>
                <Typography variant="subtitle2">
                  From Creativity to Achievement: Where Vision Meets Recognition
                </Typography>
              </Box>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="caption">
            © Copyright medicalWISDOM 2023. All rights reserved.
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="caption">
                <Link
                  underline="none"
                  href="https://craftsocially.com/photography-contest-2023"
                  target="_blank"
                  rel="noreferrer"
                >
                  CraftSocially Elder Friendly Durga Puja Photography Contest
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <Link
                  underline="none"
                  href="https://technicise.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

OnboardingLayout.propTypes = {
  children: PropTypes.node,
};

export default OnboardingLayout;
