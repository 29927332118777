import {
  emailOtpGenerationDetails,
  emailOtpGenerationActionTypes,
  EMAIL_OTP_GENERATION_FAIL,
  EMAIL_OTP_GENERATION_LOADING,
  EMAIL_OTP_GENERATION_SUCCESS,
} from "./ActionTypes";
import { emailOtpGenerationValue } from "./Model";

const initialStateGetPosts: emailOtpGenerationDetails = {
  loading: false,
  emailOtpGenerationRes: {} as emailOtpGenerationValue,
  errRes: "",
};
export const emailOtpgenerationReducer = (
  state = initialStateGetPosts,
  action: emailOtpGenerationActionTypes,
): emailOtpGenerationDetails => {
  switch (action.type) {
    case EMAIL_OTP_GENERATION_SUCCESS:
      return {
        ...state,
        loading: false,
        emailOtpGenerationRes: action.payload,
        errRes: "",
      };
    case EMAIL_OTP_GENERATION_LOADING:
      return {
        ...state,
        loading: true,
        emailOtpGenerationRes: {} as emailOtpGenerationValue,
      };
    case EMAIL_OTP_GENERATION_FAIL:
      return {
        ...state,
        emailOtpGenerationRes: {} as emailOtpGenerationValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
