import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { ContestListActionTypes } from "./ActionTypes";
import { ContestListRes } from "./Model";
import {
  contestListAPIResClearAction,
  contestListErrorAction,
  contestListLoadingAction,
  contestListSuccessAction,
  contestListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "Authentication/Interceptors";

let apiRes = {} as ContestListRes;
export const fetchContestList = (pageNo: Number) => {
  return function (dispatch: Dispatch<ContestListActionTypes>) {
    dispatch(contestListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.apiURL +
          `/competitionapp/api/competitionAsParticipant/competition_list_by_participant?page=${pageNo}&size=${10}`,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(contestListLoadingAction(false));
        dispatch(
          contestListSuccessAction(
            res.data !== undefined ? res.data : ({} as ContestListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Contest List has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(contestListLoadingAction(false));
        dispatch(
          contestListErrorAction(
            {} as ContestListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateContestListAPIResMsg = () => {
  return function (dispatch: Dispatch<ContestListActionTypes>) {
    dispatch(contestListUpdateAPIMsgAction(apiRes as ContestListRes, "", "", 0));
  };
};

export const clearContestListAPIRes = () => {
  return function (dispatch: Dispatch<ContestListActionTypes>) {
    dispatch(contestListAPIResClearAction({} as ContestListRes, "", "", 0));
  };
};
