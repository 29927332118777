import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { AddImageToCompetitionActionTypes } from "./ActionTypes";
import { AddImageToCompetitionBody, AddImageToCompetitionDetails } from "./Model";
import {
  addImageToCompetitionClearAction,
  addImageToCompetitionErrorAction,
  addImageToCompetitionLoadingAction,
  addImageToCompetitionSuccessAction,
  addImageToCompetitionAPIMsgAction,
} from "./Actions";
import { headers } from "../../components/Utility";
import { setupInterceptorsTo } from "Authentication/Interceptors";

let apiRes = {} as AddImageToCompetitionDetails;
export const addImageToCompetition = (payload: AddImageToCompetitionBody) => {
  return function (dispatch: Dispatch<AddImageToCompetitionActionTypes>) {
    dispatch(addImageToCompetitionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL +
          `/competitionapp/api/competitionPatientDetailsListAsParticipant/add_patientDetail_of_participant_in_competition`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addImageToCompetitionLoadingAction(false));
        dispatch(
          addImageToCompetitionSuccessAction(
            res.data,
            res?.data?.message !== undefined
              ? res?.data?.message
              : "Competition Details Has been updated successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addImageToCompetitionLoadingAction(false));
        dispatch(
          addImageToCompetitionErrorAction(
            {} as AddImageToCompetitionDetails,
            error.response.data.message !== undefined
              ? error.response.data.message !== ""
                ? error.response.data.message
                : "Something went wrong!"
              : "Something went wrong!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateAddImageToCompetitionAPIResMsg = () => {
  return function (dispatch: Dispatch<AddImageToCompetitionActionTypes>) {
    dispatch(addImageToCompetitionAPIMsgAction(apiRes as AddImageToCompetitionDetails, "", "", 0));
  };
};

export const clearAddImageToCompetitionAPIRes = () => {
  return function (dispatch: Dispatch<AddImageToCompetitionActionTypes>) {
    dispatch(addImageToCompetitionClearAction({} as AddImageToCompetitionDetails, "", "", 0));
  };
};
