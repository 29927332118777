import {
  ADD_IMAGE_TO_COMPETITION_FAIL,
  ADD_IMAGE_TO_COMPETITION_LOADING,
  ADD_IMAGE_TO_COMPETITION_SUCCESS,
  AddImageToCompetitionActionTypes,
  ADD_IMAGE_TO_COMPETITION_API_MSG,
  ADD_IMAGE_TO_COMPETITION_API_RES,
} from "./ActionTypes";
import { AddImageToCompetitionDetails } from "./Model";

export const addImageToCompetitionLoadingAction = (
  loading: boolean,
): AddImageToCompetitionActionTypes => {
  return {
    type: ADD_IMAGE_TO_COMPETITION_LOADING,
    loading: loading,
  };
};

export const addImageToCompetitionSuccessAction = (
  patientDetailsListByCompetitionIdResponse: AddImageToCompetitionDetails,
  successMsg: string,
): AddImageToCompetitionActionTypes => {
  return {
    type: ADD_IMAGE_TO_COMPETITION_SUCCESS,
    payload: patientDetailsListByCompetitionIdResponse,
    successMsg: successMsg,
  };
};

export const addImageToCompetitionErrorAction = (
  patientDetailsListByCompetitionIdResponse: AddImageToCompetitionDetails,
  errorMsg: string,
  status: number,
): AddImageToCompetitionActionTypes => {
  return {
    type: ADD_IMAGE_TO_COMPETITION_FAIL,
    payload: patientDetailsListByCompetitionIdResponse,
    errorMsg: errorMsg,
    status: status,
  };
};
export const addImageToCompetitionAPIMsgAction = (
  patientDetailsListByCompetitionIdResponse: AddImageToCompetitionDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): AddImageToCompetitionActionTypes => {
  return {
    type: ADD_IMAGE_TO_COMPETITION_API_MSG,
    payload: patientDetailsListByCompetitionIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const addImageToCompetitionClearAction = (
  patientDetailsListByCompetitionIdResponse: AddImageToCompetitionDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): AddImageToCompetitionActionTypes => {
  return {
    type: ADD_IMAGE_TO_COMPETITION_API_RES,
    payload: patientDetailsListByCompetitionIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
