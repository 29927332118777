import {
  USER_LIST_FAIL,
  USER_LIST_LOADING,
  USER_LIST_SUCCESS,
  GetUserListActionTypes,
  USER_LIST_API_MSG,
  USER_LIST_API_RES,
} from "./ActionTypes";
import { GetUserListDetails } from "./Model";

export const getUserListLoadingAction = (loading: boolean): GetUserListActionTypes => {
  return {
    type: USER_LIST_LOADING,
    loading: loading,
  };
};

export const getUserListSuccessAction = (
  patientDetailsListByCompetitionIdResponse: GetUserListDetails,
  successMessage: string,
): GetUserListActionTypes => {
  return {
    type: USER_LIST_SUCCESS,
    payload: patientDetailsListByCompetitionIdResponse,
    successMessage: successMessage,
  };
};

export const getUserListErrorAction = (
  patientDetailsListByCompetitionIdResponse: GetUserListDetails,
  errorMessage: string,
  status: number,
): GetUserListActionTypes => {
  return {
    type: USER_LIST_FAIL,
    payload: patientDetailsListByCompetitionIdResponse,
    errorMessage: errorMessage,
    status: status,
  };
};
export const getUserListAPIMsgAction = (
  patientDetailsListByCompetitionIdResponse: GetUserListDetails,
  successMessage: string,
  errorMessage: string,
  status: number,
): GetUserListActionTypes => {
  return {
    type: USER_LIST_API_MSG,
    payload: patientDetailsListByCompetitionIdResponse,
    successMessage: successMessage,
    errorMessage: errorMessage,
    status: status,
  };
};

export const getUserListClearAction = (
  patientDetailsListByCompetitionIdResponse: GetUserListDetails,
  successMessage: string,
  errorMessage: string,
  status: number,
): GetUserListActionTypes => {
  return {
    type: USER_LIST_API_RES,
    payload: patientDetailsListByCompetitionIdResponse,
    successMessage: successMessage,
    errorMessage: errorMessage,
    status: status,
  };
};
