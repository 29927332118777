import { LightTheme as DefaultLightTheme } from "./theme/index";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const LightTheme = createTheme({
  ...DefaultLightTheme,
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: "8px",
          minHeight: "40px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          alignItems: "start",
          justifyContent: "start",
          textAlign: "left",
          textTransform: "none",
          minHeight: 0,
          padding: "10px 20px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "#000",
          "&.Mui-selected": {
            backgroundColor: "#d1cbf4",
            color: "#2C387E",
            borderLeft: "solid 2px",
            "&.Mui-focusVisible": {
              background: "#d1cbf4",
            },
            "& .MuiListItemIcon-root": {
              color: "#d1cbf4",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px #2f2f2f inset",
            "-webkit-text-fill-color": "#fff",
          },
        },
      },
    },
  },
});

const mwTheme = responsiveFontSizes(LightTheme);
export default mwTheme;
