import {
  GetUnLinkedImageListByUserActionTypes,
  UNLINKED_IMAGE_LIST_BY_USER_FAIL,
  UNLINKED_IMAGE_LIST_BY_USER_LOADING,
  UNLINKED_IMAGE_LIST_BY_USER_SUCCESS,
  PatientDetailsListOfCompetitionIdState,
  UNLINKED_IMAGE_LIST_BY_USER_API_MSG,
  UNLINKED_IMAGE_LIST_BY_USER_API_RES,
} from "./ActionTypes";
import { GetUnLinkedImageListByUserDetails } from "./Model";

const initialStateGetPosts: PatientDetailsListOfCompetitionIdState = {
  loading: false,
  patientDetailsListOfCompetitionIdRes: {} as GetUnLinkedImageListByUserDetails,
  successMessage: "",
  errorMessage: "",
  status: 0,
};
export const getUnLinkedImageListByUserReducer = (
  state = initialStateGetPosts,
  action: GetUnLinkedImageListByUserActionTypes,
): PatientDetailsListOfCompetitionIdState => {
  switch (action.type) {
    case UNLINKED_IMAGE_LIST_BY_USER_LOADING:
      return {
        ...state,
        loading: true,
        patientDetailsListOfCompetitionIdRes: {} as GetUnLinkedImageListByUserDetails,
      };
    case UNLINKED_IMAGE_LIST_BY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: action.payload,
        successMessage: action.successMessage,
      };
    case UNLINKED_IMAGE_LIST_BY_USER_FAIL:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: {} as GetUnLinkedImageListByUserDetails,
        errorMessage: action.errorMessage,
        status: action.status,
      };
    case UNLINKED_IMAGE_LIST_BY_USER_API_MSG:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: action.payload,
        successMessage: action.successMessage,
        errorMessage: action.errorMessage,
        status: action.status,
      };
    case UNLINKED_IMAGE_LIST_BY_USER_API_RES:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: {} as GetUnLinkedImageListByUserDetails,
        successMessage: action.successMessage,
        errorMessage: action.errorMessage,
        status: action.status,
      };
    default:
      return state;
  }
};
