import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { JwtTokenActionTypes } from "./ActionTypes";
import { JwtTokenAuthBody, LoginRes } from "./Model";
import {
  loginAPIResClearAction,
  loginErrorAction,
  loginLoadingAction,
  loginSuccessAction,
  loginUpdateAPIMsgAction,
} from "./Actions";
import { ApiHeader } from "components/Utility";

let apiRes = {} as LoginRes;
export const jwtTokenApiCall = (payload: JwtTokenAuthBody) => {
  return function (dispatch: Dispatch<JwtTokenActionTypes>) {
    dispatch(loginLoadingAction(true));
    axios
      .post(Url.apiURL + `/api/token/`, payload, { headers: ApiHeader })
      .then((res) => {
        apiRes = res.data;
        dispatch(loginLoadingAction(false));
        dispatch(
          loginSuccessAction(
            res.data,
            res.data.message !== undefined ? res.data.message : "Login successful",
          ),
        );
        localStorage.setItem("access", res.data.access);
        localStorage.setItem("refresh", res.data.refresh);
      })
      .catch((error) => {
        dispatch(loginLoadingAction(false));
        dispatch(
          loginErrorAction(
            {} as LoginRes,
            error.response !== undefined
              ? error.response.data !== undefined
                ? error.response.data.message || error.response.data.non_field_errors[0]
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateLoginAPIResMsg = () => {
  return function (dispatch: Dispatch<JwtTokenActionTypes>) {
    dispatch(loginUpdateAPIMsgAction(apiRes as LoginRes, "", "", 0));
  };
};

export const clearLoginAPIRes = () => {
  return function (dispatch: Dispatch<JwtTokenActionTypes>) {
    dispatch(loginAPIResClearAction({} as LoginRes, "", "", 0));
  };
};
