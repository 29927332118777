import { emailOtpSignupValue } from "./Model";

export const EMAIL_OTP_SIGNUP_LOADING = "EMAIL_OTP_SIGNUP_LOADING";
export const EMAIL_OTP_SIGNUP_SUCCESS = "EMAIL_OTP_SIGNUP_SUCCESS";
export const EMAIL_OTP_SIGNUP_FAIL = "EMAIL_OTP_SIGNUP_FAIL";
export const EMAIL_OTP_SIGNUP_LIST = "EMAIL_OTP_SIGNUP_LIST";
export const REQUEST_BOOK_APPOINTMENT_UPDATE_LIST = "REQUEST_BOOK_APPOINTMENT_UPDATE_LIST";

export interface emailOtpSignupDetails {
  emailOtpSignupRes: emailOtpSignupValue;
  loading: boolean;
  errRes: string;
}
export interface emailOtpSignupLoading {
  type: typeof EMAIL_OTP_SIGNUP_LOADING;
}
export interface emailOtpSignupSuccess {
  type: typeof EMAIL_OTP_SIGNUP_SUCCESS;
  payload: emailOtpSignupValue;
  errRes: string;
}
export interface emailOtpSignupFail {
  type: typeof EMAIL_OTP_SIGNUP_FAIL;
  errRes: string;
}

interface emailOtpSignupAction {
  type: typeof EMAIL_OTP_SIGNUP_LIST;
  payload: emailOtpSignupValue;
  errRes: string;
}

export type emailOtpSignupActionTypes =
  | emailOtpSignupAction
  | emailOtpSignupLoading
  | emailOtpSignupSuccess
  | emailOtpSignupFail;
