import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { ImageUploadActionTypes } from "./ActionTypes";
import { ImageUploadResponse } from "./Model";
import {
  imageUploadAPIResClearAction,
  imageUploadSuccessAction,
  imageUploadErrorAction,
  imageUploadLoadingAction,
  imageUploadProgress,
  imageUploadUpdateAPIMsgAction,
} from "./Action";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as ImageUploadResponse;
const updatedHeaders = { ...headers };
updatedHeaders["Content-type"] = "multipart/form-data";

export const imageUploadApi = (formData: any) => {
  return function (dispatch: Dispatch<ImageUploadActionTypes>) {
    dispatch(imageUploadLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + "/storageapp/api/upload-image/", formData, {
        headers: updatedHeaders,
        onUploadProgress: (progress: any) => {
          const { loaded, total } = progress;
          const percentageProgress = Math.floor((loaded / total!) * 100);
          dispatch(imageUploadProgress(percentageProgress));
        },
        transformRequest: (formData: any) => formData,
      })
      .then((res: any) => {
        dispatch(imageUploadLoadingAction(false));
        dispatch(imageUploadSuccessAction(res.data, res.data.message, String(res.data.status)));
      })
      .catch((error: any) => {
        dispatch(imageUploadLoadingAction(false));
        dispatch(
          imageUploadErrorAction(
            {} as ImageUploadResponse,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? String(error.response.data.status) || "0" : "0",
          ),
        );
      });
  };
};

export const updateImageUploadAPIResMsg = () => {
  return function (dispatch: Dispatch<ImageUploadActionTypes>) {
    dispatch(imageUploadUpdateAPIMsgAction(apiRes as ImageUploadResponse, "", "", "0"));
  };
};

export const clearImageUploadAPIRes = () => {
  return function (dispatch: Dispatch<ImageUploadActionTypes>) {
    dispatch(imageUploadAPIResClearAction({} as ImageUploadResponse, "", "", "0"));
  };
};
