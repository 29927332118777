import { emailOtpGenerationValue } from "./Model";

export const EMAIL_OTP_GENERATION_LOADING = "EMAIL_OTP_GENERATION_LOADING";
export const EMAIL_OTP_GENERATION_SUCCESS = "EMAIL_OTP_GENERATION_SUCCESS";
export const EMAIL_OTP_GENERATION_FAIL = "EMAIL_OTP_GENERATION_FAIL";
export const EMAIL_OTP_GENERATION = "EMAIL_OTP_GENERATION";
export const REQUEST_BOOK_APPOINTMENT_UPDATE_LIST = "REQUEST_BOOK_APPOINTMENT_UPDATE_LIST";

export interface emailOtpGenerationDetails {
  emailOtpGenerationRes: emailOtpGenerationValue;
  loading: boolean;
  errRes: string;
}
export interface emailOtpGenerationLoading {
  type: typeof EMAIL_OTP_GENERATION_LOADING;
}
export interface emailOtpGenerationSuccess {
  type: typeof EMAIL_OTP_GENERATION_SUCCESS;
  payload: emailOtpGenerationValue;
  errRes: string;
}
export interface emailOtpGenerationFail {
  type: typeof EMAIL_OTP_GENERATION_FAIL;
  errRes: string;
}

interface emailOtpGenerationAction {
  type: typeof EMAIL_OTP_GENERATION;
  payload: emailOtpGenerationValue;
  errRes: string;
}

export type emailOtpGenerationActionTypes =
  | emailOtpGenerationAction
  | emailOtpGenerationLoading
  | emailOtpGenerationSuccess
  | emailOtpGenerationFail;
