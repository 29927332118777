import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  getUserDetailsActionTypes,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_LOADING,
  GET_USER_DETAILS_FAIL,
} from "./ActionTypes";
import { GetUserDetailsListValue } from "./Model";
import { headers } from "components/Utility";

export const getUserDetailsListApi = () => {
  return function (dispatch: Dispatch<getUserDetailsActionTypes>) {
    dispatch({
      type: GET_USER_DETAILS_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/profileapp/api/userDetails/get_details`, { headers: headers })
      .then((res) => {
        dispatch({
          type: GET_USER_DETAILS_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USER_DETAILS_FAIL,
          payload: {} as GetUserDetailsListValue,
          errRes: error.response.data.message,
        });
      });
  };
};
