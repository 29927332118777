import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { GetUserListActionTypes } from "./ActionTypes";
import { GetUserListDetails } from "./Model";
import {
  getUserListClearAction,
  getUserListErrorAction,
  getUserListLoadingAction,
  getUserListSuccessAction,
  getUserListAPIMsgAction,
} from "./Actions";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "Authentication/Interceptors";

let apiRes = {} as GetUserListDetails;
export const getUserList = () => {
  return function (dispatch: Dispatch<GetUserListActionTypes>) {
    dispatch(getUserListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/patientapp/api/patient/patient_list`, { headers: headers })
      .then((res) => {
        apiRes = res.data;
        dispatch(getUserListLoadingAction(false));
        dispatch(
          getUserListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message !== ""
                ? res.data.message
                : "User list has been fetched successfully"
              : "User list has been fetched successfully",
          ),
        );
        localStorage.setItem("defaultUserId", res.data.patientList[0].id);
      })
      .catch((error) => {
        dispatch(getUserListLoadingAction(false));
        dispatch(
          getUserListErrorAction(
            {} as GetUserListDetails,
            error.response.data.message !== undefined
              ? error.response.data.message
              : "Something went wrong!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateUserListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetUserListActionTypes>) {
    dispatch(getUserListAPIMsgAction(apiRes as GetUserListDetails, "", "", 0));
  };
};

export const clearUserListAPIRes = () => {
  return function (dispatch: Dispatch<GetUserListActionTypes>) {
    dispatch(getUserListClearAction({} as GetUserListDetails, "", "", 0));
  };
};
