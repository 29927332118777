import {
  ContestListActionTypes,
  CONTEST_LIST_FAIL,
  CONTEST_LIST_LOADING,
  CONTEST_LIST_SUCCESS,
  ContestListState,
  CONTEST_LIST_UPDATE_API_MSG,
  CONTEST_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { ContestListRes } from "./Model";

const initialStateUpdatePosts: ContestListState = {
  loading: false,
  contestListRes: {} as ContestListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const contestListReducer = (
  state = initialStateUpdatePosts,
  action: ContestListActionTypes,
): ContestListState => {
  switch (action.type) {
    case CONTEST_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case CONTEST_LIST_SUCCESS:
      return {
        ...state,
        contestListRes: action.payload,
        successMsg: action.successMsg,
      };
    case CONTEST_LIST_FAIL:
      return {
        ...state,
        contestListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CONTEST_LIST_UPDATE_API_MSG:
      return {
        ...state,
        contestListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CONTEST_LIST_UPDATE_API_RES:
      return {
        ...state,
        contestListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
