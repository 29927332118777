import {
  CONTEST_LIST_FAIL,
  CONTEST_LIST_LOADING,
  CONTEST_LIST_SUCCESS,
  CONTEST_LIST_UPDATE_API_MSG,
  CONTEST_LIST_UPDATE_API_RES,
  ContestListActionTypes,
} from "./ActionTypes";
import { ContestListRes } from "./Model";

export const contestListLoadingAction = (
  loading: boolean,
): ContestListActionTypes => {
  return {
    type: CONTEST_LIST_LOADING,
    loading: loading,
  };
};

export const contestListSuccessAction = (
  contestListResponse: ContestListRes,
  successMsg: string,
): ContestListActionTypes => {
  return {
    type: CONTEST_LIST_SUCCESS,
    payload: contestListResponse,
    successMsg: successMsg,
  };
};

export const contestListErrorAction = (
  contestListResponse: ContestListRes,
  errMsg: string,
  status: number,
): ContestListActionTypes => {
  return {
    type: CONTEST_LIST_FAIL,
    payload: contestListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const contestListUpdateAPIMsgAction = (
  contestListResponse: ContestListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ContestListActionTypes => {
  return {
    type: CONTEST_LIST_UPDATE_API_MSG,
    payload: contestListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const contestListAPIResClearAction = (
  contestListResponse: ContestListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ContestListActionTypes => {
  return {
    type: CONTEST_LIST_UPDATE_API_RES,
    payload: contestListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
