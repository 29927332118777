import React, { useEffect, useState } from "react";
import { AppState } from "../redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWLoader from "../components/MWLoader";
import PageLayout from "components/PageLayout";
import MWPageTitle from "components/MWPageTitle";
import { CompetitionList } from "redux/Dashboard/ContestList/Model";
import dayjs from "dayjs";
import { fetchContestList } from "../redux/Dashboard/ContestList/API";
import MWExceptionList from "components/MWExceptionList";
import { useHistory } from "react-router-dom";
import { getUserList } from "../redux/Dashboard/UserList/API";

export default function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();

  const loadingMarkup = <MWLoader />;
  const [loading, setLoading] = React.useState<boolean | undefined>(false);

  const contestListRes = useSelector((state: AppState) => state.contestListRes);

  useEffect(() => {
    dispatch(getUserList());
    dispatch(fetchContestList(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [contestList, setContestList] = useState([] as any[]);

  useEffect(() => {
    setLoading(contestListRes?.loading);
    if (contestListRes?.contestListRes?.competitionList !== undefined) {
      if (contestListRes?.contestListRes?.competitionList.length > 0) {
        let response = contestListRes?.contestListRes?.competitionList.map(
          (item: CompetitionList, index: number) =>
            ({
              id: item?.id,
              itemIndex: index,
              name:
                item?.displayName !== undefined
                  ? item?.displayName !== null
                    ? item.displayName !== ""
                      ? item.displayName
                      : ""
                    : ""
                  : "",
              createdDate:
                item?.dateCreated !== undefined
                  ? item?.dateCreated !== null
                    ? item?.dateCreated !== ""
                      ? dayjs(item?.dateCreated).format("DD-MM-YYYY")
                      : "N/A"
                    : "N/A"
                  : "N/A",
            } as any),
        );
        setContestList(response);
      } else setContestList([] as any[]);
    } else setContestList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contestListRes]);

  const contestTableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: `View`,
      align: "center",
      minWidth: 90,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => history.push(`competitiondetails/${page}/${params.row.id}`)}
          sx={{ textTransform: "none" }}
        >
          <VisibilityIcon />
        </Button>
      ),
    },
    {
      field: "name",
      headerName: `Name`,
      minWidth: 315,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: `Created Date`,
      align: "center",
      minWidth: 110,
    },
  ];

  const [page, setPage] = React.useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(fetchContestList(value));
  };

  return (
    <Box>
      {loading ? loadingMarkup : null}
      <PageLayout>
        <MWPageTitle title="Dashboard" />
        <Card>
          <CardHeader
            title={
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
              >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Contest List
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      startIcon={<RefreshIcon />}
                      onClick={() => dispatch(fetchContestList(page))}
                      variant="contained"
                      disabled={contestListRes?.loading}
                      sx={{ textTransform: "none" }}
                    >
                      Reload
                    </Button>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <Pagination
                      count={
                        contestListRes?.contestListRes?.count !== undefined
                          ? contestListRes?.contestListRes?.count
                          : 1
                      }
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                      onChange={handleChange}
                      disabled={contestListRes?.loading}
                    />
                  </Grid>
                </Grid>
              </Stack>
            }
          />
          <CardContent>
            {contestList && contestList.length ? (
              <DataGrid
                rows={contestList}
                columns={contestTableColumns}
                hideFooter
                hideFooterPagination
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                getRowId={(row) => row.id}
              />
            ) : (
              <MWExceptionList />
            )}
          </CardContent>
        </Card>
      </PageLayout>
    </Box>
  );
}
