import {
  UNLINKED_IMAGE_LIST_BY_USER_FAIL,
  UNLINKED_IMAGE_LIST_BY_USER_LOADING,
  UNLINKED_IMAGE_LIST_BY_USER_SUCCESS,
  GetUnLinkedImageListByUserActionTypes,
  UNLINKED_IMAGE_LIST_BY_USER_API_MSG,
  UNLINKED_IMAGE_LIST_BY_USER_API_RES,
} from "./ActionTypes";
import { GetUnLinkedImageListByUserDetails } from "./Model";

export const getUnLinkedImageListByUserLoadingAction = (
  loading: boolean,
): GetUnLinkedImageListByUserActionTypes => {
  return {
    type: UNLINKED_IMAGE_LIST_BY_USER_LOADING,
    loading: loading,
  };
};

export const getUnLinkedImageListByUserSuccessAction = (
  patientDetailsListByCompetitionIdResponse: GetUnLinkedImageListByUserDetails,
  successMessage: string,
): GetUnLinkedImageListByUserActionTypes => {
  return {
    type: UNLINKED_IMAGE_LIST_BY_USER_SUCCESS,
    payload: patientDetailsListByCompetitionIdResponse,
    successMessage: successMessage,
  };
};

export const getUnLinkedImageListByUserErrorAction = (
  patientDetailsListByCompetitionIdResponse: GetUnLinkedImageListByUserDetails,
  errorMessage: string,
  status: number,
): GetUnLinkedImageListByUserActionTypes => {
  return {
    type: UNLINKED_IMAGE_LIST_BY_USER_FAIL,
    payload: patientDetailsListByCompetitionIdResponse,
    errorMessage: errorMessage,
    status: status,
  };
};
export const getUnLinkedImageListByUserAPIMsgAction = (
  patientDetailsListByCompetitionIdResponse: GetUnLinkedImageListByUserDetails,
  successMessage: string,
  errorMessage: string,
  status: number,
): GetUnLinkedImageListByUserActionTypes => {
  return {
    type: UNLINKED_IMAGE_LIST_BY_USER_API_MSG,
    payload: patientDetailsListByCompetitionIdResponse,
    successMessage: successMessage,
    errorMessage: errorMessage,
    status: status,
  };
};

export const getUnLinkedImageListByUserClearAction = (
  patientDetailsListByCompetitionIdResponse: GetUnLinkedImageListByUserDetails,
  successMessage: string,
  errorMessage: string,
  status: number,
): GetUnLinkedImageListByUserActionTypes => {
  return {
    type: UNLINKED_IMAGE_LIST_BY_USER_API_RES,
    payload: patientDetailsListByCompetitionIdResponse,
    successMessage: successMessage,
    errorMessage: errorMessage,
    status: status,
  };
};
