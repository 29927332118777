import {
  emailOtpSignupDetails,
  emailOtpSignupActionTypes,
  EMAIL_OTP_SIGNUP_FAIL,
  EMAIL_OTP_SIGNUP_LOADING,
  EMAIL_OTP_SIGNUP_SUCCESS,
} from "./ActionTypes";
import { emailOtpSignupValue } from "./Model";

const initialStateGetPosts: emailOtpSignupDetails = {
  loading: false,
  emailOtpSignupRes: {} as emailOtpSignupValue,
  errRes: "",
};
export const emailOtpSignupReducer = (
  state = initialStateGetPosts,
  action: emailOtpSignupActionTypes,
): emailOtpSignupDetails => {
  switch (action.type) {
    case EMAIL_OTP_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        emailOtpSignupRes: action.payload,
        errRes: "",
      };
    case EMAIL_OTP_SIGNUP_LOADING:
      return {
        ...state,
        loading: true,
        emailOtpSignupRes: {} as emailOtpSignupValue,
      };
    case EMAIL_OTP_SIGNUP_FAIL:
      return {
        ...state,
        emailOtpSignupRes: {} as emailOtpSignupValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
