import { AddImageToCompetitionDetails } from "./Model";

export const ADD_IMAGE_TO_COMPETITION_LOADING = "ADD_IMAGE_TO_COMPETITION_LOADING";
export const ADD_IMAGE_TO_COMPETITION_SUCCESS = "ADD_IMAGE_TO_COMPETITION_SUCCESS";
export const ADD_IMAGE_TO_COMPETITION_FAIL = "ADD_IMAGE_TO_COMPETITION_FAIL";
export const ADD_IMAGE_TO_COMPETITION_DATA = "ADD_IMAGE_TO_COMPETITION_DATA";
export const ADD_IMAGE_TO_COMPETITION_API_MSG =
  "ADD_IMAGE_TO_COMPETITION_API_MSG";
export const ADD_IMAGE_TO_COMPETITION_API_RES =
  "ADD_IMAGE_TO_COMPETITION_API_RES";

export interface AddImageToCompetitionState {
  addPatientDetailsListInCompetitionIdRes: AddImageToCompetitionDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDetailsListOfCompetitionIdLoading {
  type: typeof ADD_IMAGE_TO_COMPETITION_LOADING;
  loading: boolean;
}
export interface AddPatientDetailsListOfCompetitionIdSuccess {
  type: typeof ADD_IMAGE_TO_COMPETITION_SUCCESS;
  payload: AddImageToCompetitionDetails;
  successMsg: string;
}
export interface PatientDetailsListOfCompetitionIdFail {
  type: typeof ADD_IMAGE_TO_COMPETITION_FAIL;
  payload: AddImageToCompetitionDetails;
  errorMsg: string;
  status: number;
}
export interface AddPatientDetailsListOfCompetitionIdAPIMsg {
  type: typeof ADD_IMAGE_TO_COMPETITION_API_MSG;
  payload: AddImageToCompetitionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDetailsListOfCompetitionIdRes {
  type: typeof ADD_IMAGE_TO_COMPETITION_API_RES;
  payload: AddImageToCompetitionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddImageToCompetitionActionTypes =
  | AddPatientDetailsListOfCompetitionIdLoading
  | AddPatientDetailsListOfCompetitionIdSuccess
  | PatientDetailsListOfCompetitionIdFail
  | AddPatientDetailsListOfCompetitionIdAPIMsg
  | AddPatientDetailsListOfCompetitionIdRes;
