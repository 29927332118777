import { ResetPasswordValue } from "./Model";

export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";

export interface ResetPasswordDetails {
  resetPasswordRes: ResetPasswordValue;
  loading: boolean;
  errRes: string;
}
export interface ResetPasswordLoading {
  type: typeof RESET_PASSWORD_LOADING;
}
export interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: ResetPasswordValue;
  errRes: string;
}
export interface ResetPasswordFail {
  type: typeof RESET_PASSWORD_FAIL;
  errRes: string;
}

interface ResetPasswordAction {
  type: typeof RESET_PASSWORD;
  payload: ResetPasswordValue;
  errRes: string;
}

export type resetPasswordActionTypes =
  | ResetPasswordAction
  | ResetPasswordLoading
  | ResetPasswordSuccess
  | ResetPasswordFail;
