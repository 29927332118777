import React from "react";
import Errorimage from "../image/design-assets/error-page.png";
import { Box, Button, Container, Grid, Typography } from "@mui/material";

function NotFound() {
  return (
    <Container maxWidth="xl" sx={{ height: "100vh" }} title="Page Not Found">
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ height: "50vh" }}
          >
            <Grid item xs={11}>
              <Box>
                <Typography variant="h4">Something went wrong</Typography>
                <p>
                  <br />
                  The specified file was not found on this website. Please check the URL for
                  mistakes and try again.
                  <br />
                  <br />
                </p>
                <Box>
                  <Button variant="contained" href="/signin">
                    Get back to Home
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box mt={10}>
            <img
              width="750vh"
              height="600vh"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={Errorimage}
              alt=""
            />
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}

export default NotFound;
