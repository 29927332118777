import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "components/MWExceptionList";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUnLinkedImageListByUser } from "../../../redux/CompetitionDetails/UnlinkedUploadedImageListByUser/API";
import { GetPatientDetailsListByCompetitionIdBody } from "redux/CompetitionDetails/PatientDetailsListOfPatientByCompetitionId/Model";
import { AppState } from "redux/store/Store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  addImageToCompetition,
  clearAddImageToCompetitionAPIRes,
} from "redux/AddPatientDetailsListInCompetitionId/API";
import { AddImageToCompetitionBody } from "redux/AddPatientDetailsListInCompetitionId/Model";
import DocumentViewer from "pages/DocumentViewer";
import MWSnackbar from "components/MWSnackbar";

export default function UnLinkedPicture() {
  const userId = localStorage.getItem("defaultUserId");
  const dispatch = useDispatch();
  const { competitionid } = useParams() as {
    competitionid: string;
  };

  useEffect(() => {
    dispatch(getUnLinkedImageListByUser(patientDetailsBody, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getUnLinkedImageListByUser(patientDetailsBody, value));
  };

  const patientDetailsBody = {
    competitionId: String(competitionid),
    patientId: String(userId!),
  } as GetPatientDetailsListByCompetitionIdBody;

  const patientPointDataList = useSelector(
    (state: AppState) => state.getUnLinkedImageListByUserRes,
  );
  const [dataGridArray, setDataArray] = useState([] as any[]);

  const [pageListCount, setPageListCount] = useState("");

  useEffect(() => {
    patientPointDataList?.patientDetailsListOfCompetitionIdRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(patientPointDataList?.patientDetailsListOfCompetitionIdRes?.numberOfPages),
        )
      : setPageListCount("0");

    if (
      patientPointDataList?.patientDetailsListOfCompetitionIdRes?.patientDetailsList !== undefined
    ) {
      let dataPointArr =
        patientPointDataList?.patientDetailsListOfCompetitionIdRes?.patientDetailsList.map(
          (element: any) => ({
            id: element.id,
            description: element.description,
            startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY h:mm A"),
            date: element.startDate,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
            category: "unlinked",
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(patientPointDataList?.patientDetailsListOfCompetitionIdRes?.numberOfPages),
      );
    }
  }, [patientPointDataList]);
  const addImageToCompetitionRes = useSelector((state: AppState) => state.addImageToCompetitionRes);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      width: 100,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            setViewImageDialogEnable(true);
            setSelectedImage(params.row.image);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Link into competition",
      type: "string",
      minWidth: 140,
      editable: true,
      sortable: false,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<InsertLinkIcon />}
          variant="outlined"
          disabled={addImageToCompetitionRes?.loading}
          onClick={() => {
            console.log("Hi");
            dispatch(
              addImageToCompetition({
                competitionId: competitionid,
                patientDetailsId: String(params.row.id),
              } as AddImageToCompetitionBody),
            );
          }}
          sx={{ textTransform: "none" }}
        >
          Link
        </Button>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      minWidth: 260,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
    },
  ];
  const [viewImageDialogEnable, setViewImageDialogEnable] = useState(false);
  const updateViewImageDialog = (passedVal: boolean) => {
    setViewImageDialogEnable(passedVal);
  };
  const [selectedImage, setSelectedImage] = useState(false);
  const updateSelectedImageDialog = (passedVal: any) => {
    setSelectedImage(passedVal);
  };

  const [linkImageToCompetitionMsg, setLinkImageToCompetitionMsg] = useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (addImageToCompetitionRes?.successMsg !== "") {
      setLinkImageToCompetitionMsgType("success");
      setLinkImageToCompetitionMsg(addImageToCompetitionRes?.successMsg);
    }
    if (addImageToCompetitionRes?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setLinkImageToCompetitionMsg(addImageToCompetitionRes?.errorMsg);
    }
  }, [addImageToCompetitionRes]);

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddImageToCompetitionAPIRes());
      setLinkImageToCompetitionMsg("");
    }
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
              </Grid>
              <Grid item>
                <Pagination
                  color="primary"
                  count={Number(pageListCount)}
                  page={page}
                  onChange={handleChange}
                  variant="outlined"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {dataGridArray && dataGridArray.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {viewImageDialogEnable ? (
        <DocumentViewer
          enable={viewImageDialogEnable}
          dialogUpdateState={updateViewImageDialog}
          selectedItem={selectedImage}
          selectedItemUpdateValue={updateSelectedImageDialog}
        />
      ) : null}
      {linkImageToCompetitionMsg !== "" ? (
        <MWSnackbar
          msg={linkImageToCompetitionMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
