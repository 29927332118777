import { GetUserListDetails } from "./Model";

export const USER_LIST_LOADING = "USER_LIST_LOADING";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_DATA = "USER_LIST_DATA";
export const USER_LIST_API_MSG = "USER_LIST_API_MSG";
export const USER_LIST_API_RES = "USER_LIST_API_RES";

export interface UserListState {
  patientDetailsListOfCompetitionIdRes: GetUserListDetails;
  loading: boolean;
  successMessage: string;
  errorMessage: string;
  status: number;
}
export interface UserListLoading {
  type: typeof USER_LIST_LOADING;
  loading: boolean;
}
export interface UserListSuccess {
  type: typeof USER_LIST_SUCCESS;
  payload: GetUserListDetails;
  successMessage: string;
}
export interface UserListFail {
  type: typeof USER_LIST_FAIL;
  payload: GetUserListDetails;
  errorMessage: string;
  status: number;
}
export interface UserListAPIMsg {
  type: typeof USER_LIST_API_MSG;
  payload: GetUserListDetails;
  successMessage: string;
  errorMessage: string;
  status: number;
}
export interface UserListRes {
  type: typeof USER_LIST_API_RES;
  payload: GetUserListDetails;
  successMessage: string;
  errorMessage: string;
  status: number;
}
export type GetUserListActionTypes =
  | UserListLoading
  | UserListSuccess
  | UserListFail
  | UserListAPIMsg
  | UserListRes;
