import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { GetUnLinkedImageListByUserActionTypes } from "./ActionTypes";
import { GetUnLinkedImageListByUserBody, GetUnLinkedImageListByUserDetails } from "./Model";
import {
  getUnLinkedImageListByUserClearAction,
  getUnLinkedImageListByUserErrorAction,
  getUnLinkedImageListByUserLoadingAction,
  getUnLinkedImageListByUserSuccessAction,
  getUnLinkedImageListByUserAPIMsgAction,
} from "./Actions";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "Authentication/Interceptors";

let apiRes = {} as GetUnLinkedImageListByUserDetails;
export const getUnLinkedImageListByUser = (
  payload: GetUnLinkedImageListByUserBody,
  _pageNo: number,
) => {
  return function (dispatch: Dispatch<GetUnLinkedImageListByUserActionTypes>) {
    dispatch(getUnLinkedImageListByUserLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/adminapp/api/patientDetails/get_patientDetailsList_from_dashboard`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getUnLinkedImageListByUserLoadingAction(false));
        dispatch(
          getUnLinkedImageListByUserSuccessAction(
            res.data,
            res?.data?.message !== undefined
              ? res?.data?.message !== ""
                ? res.data.message
                : "Image has been added successfully"
              : "Image has been added successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getUnLinkedImageListByUserLoadingAction(false));
        dispatch(
          getUnLinkedImageListByUserErrorAction(
            {} as GetUnLinkedImageListByUserDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateGetUnLinkedImageListByUserAPIResMsg = () => {
  return function (dispatch: Dispatch<GetUnLinkedImageListByUserActionTypes>) {
    dispatch(
      getUnLinkedImageListByUserAPIMsgAction(
        apiRes as GetUnLinkedImageListByUserDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetUnLinkedImageListByUserAPIRes = () => {
  return function (dispatch: Dispatch<GetUnLinkedImageListByUserActionTypes>) {
    dispatch(
      getUnLinkedImageListByUserClearAction({} as GetUnLinkedImageListByUserDetails, "", "", 0),
    );
  };
};
