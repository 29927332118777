import {
  GetUserDetailsListDetails,
  getUserDetailsActionTypes,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_LOADING,
  GET_USER_DETAILS_FAIL,
} from "./ActionTypes";
import { GetUserDetailsListValue } from "./Model";

const initialStateGetPosts: GetUserDetailsListDetails = {
  loading: false,
  patientDropdownListRes: {} as GetUserDetailsListValue,
  errRes: "",
};
export const getUserDetailsListReducer = (
  state = initialStateGetPosts,
  action: getUserDetailsActionTypes,
): GetUserDetailsListDetails => {
  switch (action.type) {
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDropdownListRes: action.payload,
        errRes: "",
      };
    case GET_USER_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        patientDropdownListRes: {} as GetUserDetailsListValue,
      };
    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        patientDropdownListRes: {} as GetUserDetailsListValue,
        loading: false,
        errRes: "",
      };
    default:
      return state;
  }
};
