import {
  Button,
  Dialog,
  IconButton,
  Slide,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  DialogTitle,
  Box,
  Alert,
  Typography,
  AlertColor,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MWUploader from "components/MWUploader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import {
  addPatientDataDetails,
  addPatientDataDetailsUpdateAPIResMsg,
  clearPatientDataPointClearAPIRes,
} from "redux/addPatientDetails/API";
import {
  clearPatientDataPointEditAPIRes,
  updatePatientDataDetailsEditAPIResMsg,
} from "redux/UpdatePatientDataPoint/API";
import { AppState } from "redux/store/Store";
import dayjs from "dayjs";
import { AddPatientDataDetailsBody } from "redux/addPatientDetails/Model";
import MWSnackbar from "components/MWSnackbar";
import { getUnLinkedImageListByUser } from "../../../redux/CompetitionDetails/UnlinkedUploadedImageListByUser/API";
import { GetUnLinkedImageListByUserBody } from "redux/CompetitionDetails/UnlinkedUploadedImageListByUser/Model";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  enable: boolean;
  dialogUpdateState: boolean | any;
  itemId?: any;
}
export default function AddImage({ enable, dialogUpdateState }: Props) {
  const userId = localStorage.getItem("defaultUserId");
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const { competitionpageno } = useParams() as { competitionpageno: string };
  useEffect(() => {
    setOpen(enable);
  }, [enable]);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const [imageId, setImageId] = React.useState("");

  const [dataPoint, setDataPoint] = React.useState("");

  const [selectedDate, setSelectedDate] = React.useState<Date | any>(new Date());
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleDialogClose = () => {
    setOpen(false);
    dialogUpdateState(false);
    dialogUpdateState(false);
    setDataPoint("");
    setSelectedDate(new Date());
    dispatch(clearPatientDataPointClearAPIRes());
    dispatch(clearPatientDataPointEditAPIRes());
    dispatch(clearImageUploadAPIRes());
  };

  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };

  const closeSummaryBanner = () => {
    dispatch(addPatientDataDetailsUpdateAPIResMsg());
    dispatch(updatePatientDataDetailsEditAPIResMsg());
  };

  const imageUploadRes = useSelector((state: AppState) => state.imageUploading);

  const dataPointBody = {
    patientId: String(userId!),
    patientDocumentId: imageId!,
    startDate: dayjs(String(selectedDate!)).format("YYYY-MM-DDTHH:mm:ss"),
    description: dataPoint!,
  } as AddPatientDataDetailsBody;

  const submitPatientData = () => {
    dispatch(addPatientDataDetails(dataPointBody));
  };
  const delImgAction = (passeVal: boolean) => {
    if (passeVal) {
      dispatch(clearImageUploadAPIRes());
    }
  };
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };

  const [updateDataPointSuccess, setUpdateDataPointSuccess] = React.useState("");
  const [alertType, setAlertType] = useState<AlertColor>("success");
  const [updateDataPointError, setUpdateDataPointError] = useState("");
  const updateDataPointSuccessDiv = updateDataPointError ? (
    <Alert
      variant="outlined"
      severity={alertType}
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      <Typography>{updateDataPointError}</Typography>
    </Alert>
  ) : null;

  const addDataDetailsStoreVal = useSelector((state: AppState) => state.addPointDataDetailsRes);

  React.useEffect(() => {
    if (addDataDetailsStoreVal?.addPatientDataDetailsRes.message !== undefined) {
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.addPatientDataDetailsRes.message);
      handleDialogClose();
      dispatch(
        getUnLinkedImageListByUser(
          {
            competitionId: competitionid,
            patientId: String(userId!),
          } as GetUnLinkedImageListByUserBody,
          Number(competitionpageno),
        ),
      );
    }
    if (addDataDetailsStoreVal?.message !== "") {
      setAlertType("error");
      setUpdateDataPointError(addDataDetailsStoreVal?.message);
    } else {
      setAlertType("success");
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataDetailsStoreVal]);
  // const updateDataDetailsVal = useSelector((state: AppState) => state.updatePatientDataDetailsRes);
  // React.useEffect(() => {
  //   if (updateDataDetailsVal?.updatePatientDataDetailsRes?.message !== undefined) {
  //     setUpdateDataPointSuccess(updateDataDetailsVal?.updatePatientDataDetailsRes?.message);
  //     handleDialogClose();
  //     dispatch(getUnLinkedImageListByUser(patientid, page));
  //     if (selectedItem !== undefined) {
  //       dispatch(getPatientDataDetailsById(selectedItem));
  //     }
  //   }
  //   if (updateDataDetailsVal?.message !== "") {
  //     setAlertType("error");
  //     setUpdateDataPointError(updateDataDetailsVal?.message);
  //   } else {
  //     setAlertType("success");
  //     setUpdateDataPointSuccess(addDataDetailsStoreVal?.message);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updateDataDetailsVal]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearPatientDataPointClearAPIRes());
      // dispatch(clearPatientDataPointEditAPIRes());
    }
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>Add Image</Grid>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoFocus
                id="data"
                label="Data"
                variant="outlined"
                value={dataPoint}
                onChange={(e) => {
                  setDataPoint(e.target.value);
                }}
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      size="small"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MWUploader
                documentType="patient-image"
                criteria="Upload a file less than 25MB."
                updatedDataState={updateDataStateFromChild}
                title={"Upload Your Image"}
                // imageUrl={value?.patientDocument?.document}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
          </Grid>
          <Box>{updateDataPointSuccessDiv}</Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={submitPatientData}
            variant="contained"
            disabled={
              imageUploadRes?.loading ||
              //   addDataDetailsStoreVal?.loading ||
              //   updateDataDetailsVal?.loading ||
              checkImgUpload
            }
          >
            Save
            {/* {value?.id !== undefined ? "Update" : "Save"} */}
          </Button>
        </DialogActions>
      </Dialog>
      <MWSnackbar msg={updateDataPointSuccess} type={alertType} alertClose={checkAlertClose} />
    </React.Fragment>
  );
}
