import { GetPatientDetailsRes } from "./Model";
export const GET_PATIENT_LOGIN_DETAILS_LOADING = "GET_PATIENT_LOGIN_DETAILS_LOADING";
export const GET_PATIENT_LOGIN_DETAILS_SUCCESS = "GET_PATIENT_LOGIN_DETAILS_SUCCESS";
export const GET_PATIENT_LOGIN_DETAILS_FAIL = "GET_PATIENT_LOGIN_DETAILS_FAIL";
export const GET_EXTRACT_OBSERVATION_DATA = "GET_EXTRACT_OBSERVATION_DATA";
export const GET_PATIENT_LOGIN_DETAILS_API_MSG = "GET_PATIENT_LOGIN_DETAILS_API_MSG";
export const GET_PATIENT_LOGIN_DETAILS_API_RES = "GET_PATIENT_LOGIN_DETAILS_API_RES";

export interface GetPatientLoginDetailsState {
  getPatientLoginDetailsRes: GetPatientDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetPatientLoginDetailsLoading {
  type: typeof GET_PATIENT_LOGIN_DETAILS_LOADING;
  loading: boolean;
}
export interface GetPatientLoginDetailsSuccess {
  type: typeof GET_PATIENT_LOGIN_DETAILS_SUCCESS;
  payload: GetPatientDetailsRes;
  successMsg: string;
}
export interface GetPatientLoginDetailsFail {
  type: typeof GET_PATIENT_LOGIN_DETAILS_FAIL;
  payload: GetPatientDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientLoginDetailsAPIMsg {
  type: typeof GET_PATIENT_LOGIN_DETAILS_API_MSG;
  payload: GetPatientDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientLoginDetailsAPIRes {
  type: typeof GET_PATIENT_LOGIN_DETAILS_API_RES;
  payload: GetPatientDetailsRes;
  errorMsg: string;
  status: number;
}

export type GetPatientDetailsActionTypes =
  | GetPatientLoginDetailsLoading
  | GetPatientLoginDetailsSuccess
  | GetPatientLoginDetailsFail
  | GetPatientLoginDetailsAPIMsg
  | GetPatientLoginDetailsAPIRes;
