import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { getUserDetailsListApi } from "../../redux/getUserDetails/API";
import { t } from "i18next";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import MWLoader from "../../components/MWLoader";
import ResetPwd from "./ResetPwd";
import AddUserEmail from "./AddUserEmail";
import PageLayout from "../../components/PageLayout";

export default function UserProfile() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUsername] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  // Reset Password toggle
  const [isResetPwdOpen, setIsResetPwdOpen] = useState(false);
  const toggleResetPwd = () => setIsResetPwdOpen(!isResetPwdOpen);
  // Edit Email toggle
  const [isEditEmailOpen, setIsEditEmailOpen] = useState(false);
  const toggleEditEmail = () => setIsEditEmailOpen(!isEditEmailOpen);

  // Get userprofile API
  const getUserProfile = () => {
    dispatch(getUserDetailsListApi());
  };

  useEffect(() => {
    dispatch(getUserDetailsListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetailsList = useSelector(
    (state: AppState) => state.getUserDetailsList
  );
  useEffect(() => {
    getUserDetailsList?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [getUserDetailsList]);

  useEffect(() => {
    if (getUserDetailsList?.patientDropdownListRes?.userDetails !== undefined) {
      let userDetails = getUserDetailsList?.patientDropdownListRes.userDetails;
      setUsername(userDetails.username);
      setFirstName(userDetails.firstName);
      setLastName(userDetails.lastName);
      setEmail(userDetails.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserDetailsList]);

  // Table Markup
  const userprofilePageMarkup = (
    <Card>
      <CardHeader
        action={
          <Box>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={getUserProfile}>
              {t("userprofile.reload", { ns: ["home"] })!}
            </Button>
            <Button variant="outlined" onClick={toggleResetPwd}>
              {t("userprofile.resetpsword", { ns: ["home"] })!}
            </Button>
          </Box>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableBody>
              {[
                {
                  term: `${t("userprofile.uname", { ns: ["home"] })}`,
                  description: userName,
                },
                {
                  term: `${t("signup.fname", { ns: ["home"] })}`,
                  description: firstName,
                },
                {
                  term: `${t("signup.lname", { ns: ["home"] })}`,
                  description: lastName,
                },
                {
                  term: `${t("loginpage.emailorphno", { ns: ["home"] })}`,
                  description:
                    email === "" ? (
                      <Button onClick={toggleEditEmail}>
                        {t("userprofile.addemail", { ns: ["home"] })!}
                      </Button>
                    ) : (
                      email
                    ),
                },
              ].map((row) => (
                <TableRow key={row.term}>
                  <TableCell component="th" scope="row">
                    <strong>{row.term}</strong>
                  </TableCell>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {loadingMarkup}
      <PageLayout>
        <Typography variant="h5" sx={{ mb: 2 }}>
          <b>{t("userprofile.userdetails", { ns: ["home"] })!}</b>
        </Typography>
        <ResetPwd
          toggleResetPwd={toggleResetPwd}
          isResetPwdOpen={isResetPwdOpen}
        />
        <AddUserEmail
          toggleEditEmail={toggleEditEmail}
          isEditEmailOpen={isEditEmailOpen}
        />
        {userprofilePageMarkup}
      </PageLayout>
    </Box>
  );
}
