import { combineReducers } from "redux";
import { logOutReducer } from "../logOut/Reducer";
import { emailOtpgenerationReducer } from "../emailOtpGeneration/Reducer";
import { emailOtpSignupReducer } from "../emailOtpSignup/Reducer";
import { emailOtpAuthReducer } from "../emailOtpAuth/Reducer";
import { forgotPasswordReducer } from "../forgotPassword/Reducer";
import { needHelpReducer } from "../needHelp/addNeedHelp/Reducer";
import { dropdownReducer } from "../needHelp/patientList/Reducer";
import { getUserDetailsListReducer } from "../getUserDetails/Reducer";
import { resetPasswordReducer } from "../resetPassword/Reducer";
import { setEmailPhoneReducer } from "../setEmailOrPhone/Reducer";
import { imageUploadReducer } from "../ImageUpload/Reducer";
import { jwtAuthTokenReducer } from "../jwtPatientLogIn/Reducer";
import { getPatientLoginDetailsReducer } from "../jwtPatientLogIn/patientLoginDetails/Reducer";
import { contestListReducer } from "redux/Dashboard/ContestList/Reducer";
import { getPatientDetailsByCompetitionIdReducer } from "redux/CompetitionDetails/PatientDetailsListOfPatientByCompetitionId/Reducer";
import { getUnLinkedImageListByUserReducer } from "redux/CompetitionDetails/UnlinkedUploadedImageListByUser/Reducer";
import { addImageToCompetitionReducer } from "redux/AddPatientDetailsListInCompetitionId/Reducer";
import { addPointDataDetailsReducer } from "redux/addPatientDetails/Reducer";
import { getUserListReducer } from "../Dashboard/UserList/Reducer";
const Reducer = combineReducers({
  emailOtpGeneration: emailOtpgenerationReducer,
  emailOtpSignup: emailOtpSignupReducer,
  emailOtpAuth: emailOtpAuthReducer,
  apiAuthToken: jwtAuthTokenReducer,
  forgotPassword: forgotPasswordReducer,
  logOutRes: logOutReducer,
  imageUploading: imageUploadReducer,
  addNeedHelp: needHelpReducer,
  dropdown: dropdownReducer,
  getUserDetailsList: getUserDetailsListReducer,
  resetPassword: resetPasswordReducer,
  setEmailPhone: setEmailPhoneReducer,
  getPatientLoginDetailsRes: getPatientLoginDetailsReducer,
  contestListRes: contestListReducer,
  getPatientDetailsByCompetitionRes: getPatientDetailsByCompetitionIdReducer,
  getUnLinkedImageListByUserRes: getUnLinkedImageListByUserReducer,
  addPointDataDetailsRes: addPointDataDetailsReducer,
  addImageToCompetitionRes: addImageToCompetitionReducer,
  getUserListRes: getUserListReducer,
});

export default Reducer;
