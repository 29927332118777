import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";
import {
  dropdownActionTypes,
  DROP_DOWN_FAIL,
  DROP_DOWN_LOADING,
  DROP_DOWN_SUCCESS,
} from "./ActionTypes";
import { DropDownResponse } from "./Model";
import { headers } from "components/Utility";

export const dropdownApi = () => {
  return function (dispatch: Dispatch<dropdownActionTypes>) {
    dispatch({
      type: DROP_DOWN_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/patientapp/api/patient/patient_list_dropdown`, { headers: headers })
      .then((res) => {
        dispatch({
          type: DROP_DOWN_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: DROP_DOWN_FAIL,
          payload: {} as DropDownResponse,
          errRes: error.response.data.message,
        });
      });
  };
};
