import { GetUnLinkedImageListByUserDetails } from "./Model";

export const UNLINKED_IMAGE_LIST_BY_USER_LOADING = "UNLINKED_IMAGE_LIST_BY_USER_LOADING";
export const UNLINKED_IMAGE_LIST_BY_USER_SUCCESS = "UNLINKED_IMAGE_LIST_BY_USER_SUCCESS";
export const UNLINKED_IMAGE_LIST_BY_USER_FAIL = "UNLINKED_IMAGE_LIST_BY_USER_FAIL";
export const UNLINKED_IMAGE_LIST_BY_USER_DATA = "UNLINKED_IMAGE_LIST_BY_USER_DATA";
export const UNLINKED_IMAGE_LIST_BY_USER_API_MSG = "UNLINKED_IMAGE_LIST_BY_USER_API_MSG";
export const UNLINKED_IMAGE_LIST_BY_USER_API_RES = "UNLINKED_IMAGE_LIST_BY_USER_API_RES";

export interface PatientDetailsListOfCompetitionIdState {
  patientDetailsListOfCompetitionIdRes: GetUnLinkedImageListByUserDetails;
  loading: boolean;
  successMessage: string;
  errorMessage: string;
  status: number;
}
export interface PatientDetailsListOfCompetitionIdLoading {
  type: typeof UNLINKED_IMAGE_LIST_BY_USER_LOADING;
  loading: boolean;
}
export interface PatientDetailsListOfCompetitionIdSuccess {
  type: typeof UNLINKED_IMAGE_LIST_BY_USER_SUCCESS;
  payload: GetUnLinkedImageListByUserDetails;
  successMessage: string;
}
export interface PatientDetailsListOfCompetitionIdFail {
  type: typeof UNLINKED_IMAGE_LIST_BY_USER_FAIL;
  payload: GetUnLinkedImageListByUserDetails;
  errorMessage: string;
  status: number;
}
export interface PatientDetailsListOfCompetitionIdAPIMsg {
  type: typeof UNLINKED_IMAGE_LIST_BY_USER_API_MSG;
  payload: GetUnLinkedImageListByUserDetails;
  successMessage: string;
  errorMessage: string;
  status: number;
}
export interface PatientDetailsListOfCompetitionIdRes {
  type: typeof UNLINKED_IMAGE_LIST_BY_USER_API_RES;
  payload: GetUnLinkedImageListByUserDetails;
  successMessage: string;
  errorMessage: string;
  status: number;
}

export type GetUnLinkedImageListByUserActionTypes =
  | PatientDetailsListOfCompetitionIdLoading
  | PatientDetailsListOfCompetitionIdSuccess
  | PatientDetailsListOfCompetitionIdFail
  | PatientDetailsListOfCompetitionIdAPIMsg
  | PatientDetailsListOfCompetitionIdRes;
