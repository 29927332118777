import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { GetPatientDetailsActionTypes } from "./ActionTypes";
import { GetPatientDetailsRes, LoginBody } from "./Model";
import {
  getPatientDetailsAPIResClearAction,
  getPatientDetailsErrorAction,
  getPatientDetailsLoadingAction,
  getPatientDetailsSuccessAction,
  getPatientDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";

let apiRes = {} as GetPatientDetailsRes;
export const patientLoginDetailsApiCall = (payload: LoginBody) => {
  let APIHeader = headers;
  APIHeader.Authorization = `Bearer ${payload.access}`;
  return function (dispatch: Dispatch<GetPatientDetailsActionTypes>) {
    dispatch(getPatientDetailsLoadingAction(true));
    axios
      .get(Url.apiURL + `/patientapp/api/patient/patientLoginDetails`, {
        headers: APIHeader,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientDetailsLoadingAction(false));
        dispatch(
          getPatientDetailsSuccessAction(
            res.data,
            res.data.message !== undefined ? res.data.message : "Login successful",
          ),
        );
        localStorage.setItem("loginDetails", JSON.stringify(res.data!)!);
        localStorage.setItem("firstname", res.data.user.first_name!);
        localStorage.setItem("lastname", res.data.user.last_name!);
        localStorage.setItem("email", res.data.user.email!);
      })
      .catch((error) => {
        dispatch(getPatientDetailsLoadingAction(false));
        dispatch(
          getPatientDetailsErrorAction(
            {} as GetPatientDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getPatientDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientDetailsActionTypes>) {
    dispatch(getPatientDetailsAPIMsgAction(apiRes as GetPatientDetailsRes, "", 0));
  };
};

export const clearGetPatientDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientDetailsActionTypes>) {
    dispatch(getPatientDetailsAPIResClearAction({} as GetPatientDetailsRes, "", 0));
  };
};
