import {
  AddImageToCompetitionActionTypes,
  ADD_IMAGE_TO_COMPETITION_FAIL,
  ADD_IMAGE_TO_COMPETITION_LOADING,
  ADD_IMAGE_TO_COMPETITION_SUCCESS,
  AddImageToCompetitionState,
  ADD_IMAGE_TO_COMPETITION_API_MSG,
  ADD_IMAGE_TO_COMPETITION_API_RES,
} from "./ActionTypes";
import { AddImageToCompetitionDetails } from "./Model";

const initialStateGetPosts: AddImageToCompetitionState = {
  loading: false,
  addPatientDetailsListInCompetitionIdRes: {} as AddImageToCompetitionDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addImageToCompetitionReducer = (
  state = initialStateGetPosts,
  action: AddImageToCompetitionActionTypes,
): AddImageToCompetitionState => {
  switch (action.type) {
    case ADD_IMAGE_TO_COMPETITION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_IMAGE_TO_COMPETITION_SUCCESS:
      return {
        ...state,
        addPatientDetailsListInCompetitionIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_IMAGE_TO_COMPETITION_FAIL:
      return {
        ...state,
        addPatientDetailsListInCompetitionIdRes: {} as AddImageToCompetitionDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_IMAGE_TO_COMPETITION_API_MSG:
      return {
        ...state,
        addPatientDetailsListInCompetitionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_IMAGE_TO_COMPETITION_API_RES:
      return {
        ...state,
        addPatientDetailsListInCompetitionIdRes: {} as AddImageToCompetitionDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
