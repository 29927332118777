import React from "react";
import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import Login from "../pages/PatientOnBoarding/Login";
import Signup from "../pages/PatientOnBoarding/Signup";
import NotFound from "../pages/NotFound";
import NeedHelp from "../pages/NeedHelp";
import Dashboard from "../pages/Dashboard";
import Userprofile from "../pages/UserProfile/Userprofile";
import CompetitionDetails from "pages/CompetitionDetails";

function ComponentRouter() {
  return (
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/signin" component={Login} />
      <PublicRoute exact path="/signup" component={Signup} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/needhelp" component={NeedHelp} />
      <PrivateRoute path="/userprofile" component={Userprofile} />
      <PrivateRoute path="/competitiondetails/:competitionpageno?/:competitionid?" component={CompetitionDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default ComponentRouter;
