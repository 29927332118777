export class AgeRange {
  static ageRangeCheck(age: number) {
    if (age >= 0 && age <= 10) {
      return "1-10 year old";
    } else if (age >= 10 && age <= 20) {
      return "10-20 year old";
    } else if (age >= 20 && age <= 30) {
      return "20-30 year old";
    } else if (age >= 30 && age <= 40) {
      return "30-40 year old";
    } else if (age >= 40 && age <= 50) {
      return "40-50 year old";
    } else if (age >= 50 && age <= 60) {
      return "50-60 year old";
    } else if (age >= 60 && age <= 70) {
      return "60-70 year old";
    } else if (age >= 70 && age <= 80) {
      return "70-80 year old";
    } else if (age >= 80 && age <= 90) {
      return "80-90 year old";
    } else if (age >= 90 && age <= 100) {
      return "90-100 year old";
    } else {
      return "";
    }
  }
}
let headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("access")}`,
  "Accept-Language": localStorage.getItem("lng") === null ? "en" : localStorage.getItem("lng"),
} as any;

let ApiHeader = {
  "Content-type": "application/json",
  "Accept-Language": localStorage.getItem("lng") === null ? "en" : localStorage.getItem("lng"),
} as any;

export { headers, ApiHeader };
