import {
  dropdownDetails,
  dropdownActionTypes,
  DROP_DOWN_FAIL,
  DROP_DOWN_LOADING,
  DROP_DOWN_SUCCESS,
} from "./ActionTypes";
import { DropDownResponse } from "./Model";

const initialStateGetPosts: dropdownDetails = {
  loading: false,
  patientList: {} as DropDownResponse,
  errRes: "",
};
export const dropdownReducer = (
  state = initialStateGetPosts,
  action: dropdownActionTypes,
): dropdownDetails => {
  switch (action.type) {
    case DROP_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        patientList: action.payload,
        errRes: "",
      };
    case DROP_DOWN_LOADING:
      return {
        ...state,
        loading: true,
        patientList: {} as DropDownResponse,
      };
    case DROP_DOWN_FAIL:
      return {
        ...state,
        patientList: {} as DropDownResponse,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
